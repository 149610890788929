import { Component, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { AlertService } from '../alert.service';
import { GlobalService } from '../global.service';
import { LoadingService } from '../loading.service';
import { SubirService } from '../subir.service';
import * as moment from 'moment';
import * as $ from 'jquery';
import { number } from 'echarts';
moment.locale('ES');

@Component({
  selector: 'app-newiver',
  templateUrl: './newiver.page.html',
  styleUrls: ['./newiver.page.scss'],
})
export class NewiverPage implements OnInit {
  ive;
  inversionista;
  porcentaje;
  suma = new Array();
  sum = 0;
  tot = 0
  newinversionista;
  invernew = new Array();
  constructor(public alert: AlertService, public subir: SubirService, public popoverController: PopoverController, public global: GlobalService, public loading: LoadingService, public nav: NavController) { }

  ngOnInit() {
    if (this.global.IdInver == null || this.global.IdInver == undefined) {
      this.global.Popover.dismiss(null, 'Cancelado');
    }
    const datos3 = { option: 'consultar_inversionista' };
    this.global.consultar(datos3, (err3, response3) => {
      console.log('ive', response3);
      if (response3) {
        this.ive = response3;
      }
    });

    const data8 = { option: "insertar", texto: "SELECT * FROM db_suncapital.Inver A INNER JOIN db_suncapital.Inversionista B ON A.Id_Inversionista = B.Id WHERE A.Id_Propuesta = '" + this.global.IdInver + "'  AND A.IsDelete = 0;" }
    this.global.consultar(data8, (err8, response8) => {
      console.log(data8, response8);
      if (response8) {
        this.newinversionista = response8;
        this.invernew = response8;
        this.sumar();
        this.loading.HideLoading()
      }
    });

  }

  sumar() {
    console.log(this.invernew)
    for (let index = 0; index < this.invernew.length; index++) {
      this.suma.push(this.invernew[index].Porcentaje);
    }
    this.tot = this.suma.reduce((valorAnterior, valorActual) => {
      return valorAnterior + valorActual;
    }, 0);

    console.log(this.tot);

  }

  AgregaInvesionita() {

    let total = Number(this.tot) + Number(this.porcentaje)
    console.log(total, this.tot, this.porcentaje);
    if (total > 100) {
      console.log(total)
      this.alert.AlertOneButton('Informacion', 'Tenga en cuenta que el máximo es el 100%', 'Ok', () => {
        total = 0;
        this.tot = 0;
        this.suma = [];
        this.ngOnInit();
        this.inversionista = " ";
        this.porcentaje = " ";
      });
    } else {
      if (this.inversionista == null || this.inversionista == undefined || this.porcentaje == null || this.porcentaje == undefined) {
        this.alert.AlertOneButton('Informacion', 'Por favor valide que todos los campos', 'Ok')
      } else {
        const pdata1 = {
          option: 'InsertarInversi',
          id_inver: 'I-' + moment().format('x'),
          id_propuesta: this.global.IdInver,
          id_inv: this.inversionista,
          porcentaje: this.porcentaje
        }
        // console.log(query);
        // const pdata1 = { option: 'insertar', texto: query };
        this.global.consultar(pdata1, (err, response) => {
          console.log(response);
          if (response) {
            this.alert.presentToast();
            this.tot = 0;
            total = 0;
            this.suma = [];
            this.inversionista = " ";
            this.porcentaje = " ";
            this.ngOnInit();
          } else if (response === null || response === undefined) {
            this.alert.AlertOneButton('Error', 'No se pudo Eliminar', 'Ok', () => {
              this.ngOnInit();
            });
          }
        });
      }
    }
  }
  EliminarInvesionita(id) {
    const query = `UPDATE db_suncapital.Inver SET IsDelete = 1 WHERE Id_Inver = '${id}' ;`;
    console.log(query);
    const pdata1 = { option: 'insertar', texto: query };
    this.global.consultar(pdata1, (err, response) => {
      console.log(response);
      if (response) {
        this.alert.presentToastDelete();
        this.tot = 0;
        this.suma = [];
        this.ngOnInit();
      } else if (response === null || response === undefined) {
        this.alert.AlertOneButton('Error', 'No se pudo Eliminar', 'Ok', () => {
          this.ngOnInit();
        });
      }
    });
  }
  Cerrar() {
    this.global.Popover.dismiss(null, 'terminar');
  }
}
