import { AlertService } from './../alert.service';
import { Component, OnInit } from '@angular/core';
import { SubirService } from '../subir.service';
import { NavController, PopoverController } from '@ionic/angular';
import { GlobalService } from '../global.service';
import { LoadingService } from '../loading.service';

@Component({
  selector: 'app-newperso',
  templateUrl: './newperso.page.html',
  styleUrls: ['./newperso.page.scss'],
})
export class NewpersoPage implements OnInit {
  searchQuery;
  personas;
  dire;
  constructor(public alert: AlertService, public subir: SubirService, public popoverController: PopoverController, public global: GlobalService, public loading: LoadingService, public nav: NavController) { }

  ngOnInit() {
    if (this.global.Idperso == null || this.global.Idperso == undefined) {
      this.global.Popover.dismiss(null, 'Cancelado');
    }
    const data4 = { option: "insertar", texto: "SELECT A.*,H.* FROM db_suncapital.Propuesta P INNER JOIN db_suncapital.Personas_Proyectos A ON P.Id_propuesta = A.Id_Proyecto INNER JOIN db_suncapital.User H ON A.Id_Persona = H.Id_User WHERE P.Id_propuesta ='" + this.global.Idperso + "'  AND A.IsDelete= 0;" }
    this.global.consultar(data4, (err4, response4) => {
      console.log(data4, response4);
      if (response4) {
        this.dire = response4;
      }
    });
  }

  Cerrar() {
    this.global.Popover.dismiss(null, 'Cargado');
  }
  getItems(ev: any) {
    this.Personas();
    const val = ev.target.value;
    this.searchQuery = val;
  }

  Personas() {
    const Per = { option: "insertar", texto: "SELECT A.Id_User,A.UserName,A.UserOccupation,A.UserEmail,P.Id_propuesta FROM db_suncapital.Propuesta P INNER JOIN db_suncapital.User A ON A.Id_Client = P.Cliente OR A.Id_Epc = P.EPC WHERE A.Id_User NOT IN(SELECT  W.Id_Persona	FROM db_suncapital.Personas_Proyectos W WHERE W.Id_Proyecto = '" + this.global.Idperso + "' AND W.IsDelete = 0 )AND P.Id_propuesta = '" + this.global.Idperso + "' AND A.IsDelete = 0 ;" }
    this.global.consultar(Per, (err, response) => {
      console.log(Per, response);
      if (response) {
        if (this.searchQuery && this.searchQuery.trim() != '') {
          this.personas = response.filter((item) => {
            console.log('Busqueda Q1', JSON.stringify(item));
            return (JSON.stringify(item).toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1);
          });
        }else{
          this.personas = [];
        }
      } else if (response === null || response === undefined) {
        console.log("Error Personas", err, response);
      }
    });
  }

  PerAgregar(item) {
    const pdata1 = {
      option: 'InsertarPersonaNew',
      id_proyecto: item.Id_propuesta,
      id_persona: item.Id_User,
      categoria: null
    }
    // console.log(query);
    // const pdata1 = { option: 'insertar', texto: query };
    this.global.consultar(pdata1, (err, response) => {
      console.log(response);
      if (response) {
        this.alert.presentToast();
        this.ngOnInit();
      } else if (response === null || response === undefined) {
        this.alert.AlertOneButton('Error', 'No se pudo Eliminar', 'Ok', () => {
          this.ngOnInit();
        });
      }
    });
  }

  EliminarPersona(id) {
    this.alert.AlertTowButtonss("Informacion", "¿Esta seguro que desea Borra esta persona?", "Aceptar", (res) => {
      console.log("Esto es  respuesta", res)
      if (res == true) {
        console.log(id, "ID::")
        const query = `UPDATE db_suncapital.Personas_Proyectos SET IsDelete = 1 WHERE Id_PerPro =${id} ;`;
        console.log(query);
        const pdata1 = { option: 'insertar', texto: query };
        this.global.consultar(pdata1, (err, response) => {
          console.log(response);
          if (response) {
            this.alert.presentToastDelete();
            this.ngOnInit();
          } else if (response === null || response === undefined) {
            this.alert.AlertOneButton('Error', 'No se pudo Eliminar', 'Ok', () => {
              this.ngOnInit();
            });
          }
        });
      } else {

      }
    });

  }

}
