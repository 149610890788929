import { Component, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { AlertService } from '../alert.service';
import { GlobalService } from '../global.service';
import { LoadingService } from '../loading.service';
import { SubirService } from '../subir.service';

@Component({
  selector: 'app-editepc',
  templateUrl: './editepc.page.html',
  styleUrls: ['./editepc.page.scss'],
})
export class EditepcPage implements OnInit {
  Nit;
  Nombre;
  Telefono;
  Correo;
  Tipocontra;
  constructor(public alert: AlertService, public subir: SubirService, public popoverController: PopoverController, public global: GlobalService, public loading: LoadingService, public nav: NavController) { }

  ngOnInit() {
    if(this.global.idcliente == null || this.global.idcliente == undefined){
      this.global.Popover.dismiss(null, 'Cancelado');
    }else{
      const datos = {option: 'insertar', texto: "SELECT * FROM db_suncapital.EPC WHERE Id = '"+this.global.idcliente+"' AND IsDelete=0;"};
      this.global.consultar( datos, (err, response) => {
        console.log('CLI2', response);
        this.Nit= response[0]["NIT"];
        this.Nombre= response[0]["Nombre"];
        this.Telefono= response[0]["Telefono"];
        this.Correo = response[0]["Correo"];
        this.Tipocontra = response[0]["TContrato"];
      });
    }
  }

  Cerrar() {
    this.global.Popover.dismiss(null, 'Cancelado');
  }
  Modificar(){
    if(this.Nit == null || this.Nit == undefined || this.Nombre == null || this.Nombre == undefined || this.Telefono == null || this.Telefono == undefined ||
      this.Correo == null || this.Correo == undefined || this.Tipocontra == null || this.Tipocontra == undefined){
        this.alert.AlertOneButton('Informacion', 'Por favor valide que todos los campos' , 'Ok')
    }else{
      this.loading.LoadingNormal("Actualizando Datos...")
      setTimeout(() => {
        try {
          const query = 'UPDATE db_suncapital.EPC SET '
            + 'NIT = ' + '\'' + this.Nit + '\','
            + 'Nombre = ' + '\'' + this.Nombre + '\','
            + 'Telefono = ' + '\'' + this.Telefono + '\','
            + 'Correo = ' + '\'' + this.Correo + '\','
            + 'TContrato = ' + '\'' + this.Tipocontra + '\''
            + 'WHERE Id =' + '\'' + this.global.idcliente + '\';';
          const pdata1 = { option: 'insertar', texto: query };
          console.log(pdata1);
          this.global.consultar(pdata1, (err, response) => {
            console.log(response, query);
            if(response){
              this.loading.HideLoading();
              this.alert.presentToastModic();
                this.global.Popover.dismiss(null, 'Cargado');
            }else if (response === null || response === undefined) {
              this.loading.HideLoading();
              this.alert.AlertOneButton('Error', 'No fue posible modificar el cliente', 'Ok', ()=> {
                this.global.Popover.dismiss(null, 'Cancelado');
              });
            }
          });
        } catch (e) {
          console.log(e);
        }
      }, 600);

    }
  }

}
